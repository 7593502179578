.page-account {

    .avatar-menu-container {
        display: flex;
        align-items: center;
    }

    .avatar-menu-container {
        .avatar {
            width: 96px;
            height: 96px;
            border-radius: 4px;
        }
    }

    .avatar-menu-options {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
    }

    .form-label {
        color: #707274;
        font-size: 14px;
        margin-bottom: 8px;
    }

    .user-list {
        border-radius: 4px;
        padding: 0;
        border: 1px solid var(--outline-color);
    }

    .user-list>.user-list-item:first-child {
        border-top: none;
    }

    .user-list>.user-list-item {
        border-top: 1px solid var(--outline-color);
        padding: 15px;
    }

    // .user-list-item {
    //     i {
    //         font-size: 18px;
    //         color: var(--outline-color);
    //     }
    // }

    .page-box-tools {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: var(--page-box-padding);
        gap: 32px;
        width: 100%;
        background: var(--white);
        padding: 35px 32px 32px 32px;
      
        border: var(--borders);
        border-radius: var(--border-radius);

    }

    .page-box-tools {

        .page-box-tools-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // background-color: gold;
        }

        header {
            font-weight: 700;
            font-size: 16px;
            color: var(--text-color-dark);
        }
    }

}
