:root {

  /*(Padrão cores institucional)*/
  --inst-color-very-dark: #581F77;
  --inst-color-dark: #963CC8;
  --inst-color-default: #B279D9;
  --inst-color-light: #CFABE9;
  --inst-color-very-light: #F5EEFA;

  /*Themas*/
  --theme-color-very-dark: var(--inst-color-very-dark);
  --theme-color-dark: var(--inst-color-dark);
  --theme-color-default: var(--inst-color-default);
  --theme-color-light: var(--inst-color-light);
  --theme-color-very-light: var(--inst-color-very-light);

  /*Positivo*/
  --success-color-very-dark: #024529;
  --success-color-dark: #0C7A4C;
  --success-color-default: #1AA569;
  --success-color-light: #52CF90;
  --success-color-very-light: #E1F6E8;

  /*Atenção*/
  --warning-color-very-dark: #5C3004;
  --warning-color-dark: #8F4E06;
  --warning-color-default: #E27F0D;
  --warning-color-light: #F0AA71;
  --warning-color-very-light: #FEEEE1;

  /*Negativo*/
  --error-color-very-dark: #6B2222;
  --error-color-dark: #A63839;
  --error-color-default: #FD5B5B;
  --error-color-light: #FF9F99;
  --error-color-very-light: #FDEDEC;

  /*Fundos*/
  --background-color-dark: #23152B;
  --background-color-light: #F6F8FA;
  
  /*Textos*/
  --text-color-dark: #23152B;
  --text-color-default: #343536;
  --text-color-light: #707274;
  --text-color-placeholder: #8D8E8F;
  --text-color-very-light: silver;
  
  /*Globais*/
  --white: #FFFFFF;
  --outline-color: #E0E2E4;
  --icon-color-light: #BBBDBF;
  --hover-color: rgba(30, 80, 130, 0.04);
  --elevation-color: rgba(0, 0, 0, 0.08);
  --neutral-color: rgba(164, 177, 190, 0.2);

  /*Blocos*/
  --block-color-blue: #3C85C8;
  --block-color-green: #629849;
  --block-color-orange: #ED8355;

  /*Badge*/
  --badge-color: var(--neutral-color);


  --borders: 1px solid var(--outline-color);
  --borders-danger: 1px solid var(--error-color-default);
  --border-radius: 8px;
  --main-menu-height: 64px;
  --header-height: 72px;
  --page-box-padding: 16px;

  --avatar-bg-color: #3D3348;

  --bg-note-color: #fffcdb;

}