.page-box-accordion {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--page-box-padding);
    gap: 32px;
    width: 100%;
    background: var(--white);
    padding: 20px 32px 20px 32px;
    border: var(--borders);
    border-radius: var(--border-radius);
    flex: none;
    order: 0;
    flex-grow: 0;
}

.page-box-accordion {
    .simple-accordion {
        width: 100%;
        box-shadow: none;
        padding: 0px;
        margin: 0px;
        height: 50%;
    }

    .simple-accordion-summary {
        padding: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .simple-accordion-details {
        padding-left: 0px;
        padding-right: 0px;
    }

    header {
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        span {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-left: auto;
            margin-right: 1.5%;
        }

        .simple-accordion-typography {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 80%;
            width: 50%;
            text-align: left;
            margin-left: 0.5%;
        }

        .checkbox-circle {
            color: var(--success-color-default);
            height: 100%;
            width: 30px;
            font-size: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
}
