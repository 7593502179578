
.chat-box {
    display: flex;
    flex-direction: column-reverse;
    /*@todo ver se o safe funciona em diversos navegadores*/
    /* justify-content: safe flex-end; */
    /* align-items: left; */
    gap: 32px;
    flex-shrink: 0; 
    flex: auto;
    
    /*auto height*/
    height: 0;
    overflow-y: auto;
    margin: 16px;

}

.chat-box {

    .message {
        display: flex;
        width: 75%;
        align-items: flex-start;
        gap: 16px; 
        /* background-color: lightblue; */
    }

    .message {

        .avatar-info {
            position: relative;
        }

        .avatar-info {
            & > svg, & > .icon-block, & > .icon-contained {
                position: absolute;
                bottom: -4px;
                right: -4px;
            }
        }

    }

    .information {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0; 
    }

    .sender {
        display: flex;
        align-items: flex-start;
        gap: 8px; 
    }

    .balloons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        align-self: stretch; 
    }

    .balloons {

        .balloon-container {
            display: flex;
        }

        .status-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 0px 3px;
        }

    }

    .message-balloon {
        position: relative;
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        
        /* align-self: stretch; */ 

        border-radius: 0px 8px 8px 8px;
        border-bottom: 1px solid var(--elevation-color);

        color: var(--text-color-default);

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;

        &.sent {
            background: var(--theme-color-very-light);
        }

        &.received {
            background: var(--white);
        }

        &.selected {
            background: var(--theme-color-very-dark);
            color: var(--white);
        }

        video, img {
            max-width: 24vw;
        }

        &.sticker {
            background: none;
            border: none;
            img {
                max-width: 70px;
            }
        }

        .message-menu {
            position: absolute;
            top: 0;
            right: 0;
        }

        &.log {
            border: none;
            background: none;
            width: 100%;
            padding: 0;
            color: var(--text-color-placeholder);
            font-size: 13px;
        }

        &.note {
            border: none;
            background-color: var(--bg-note-color) !important;
            font-size: 14px;
        }

    }

    .message-balloon {

        .top-0 {
            position: absolute;
            top: 0;
        }

        .menu-button {

            /* Auto layout */
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 4px 8px;
           
            width: 8px;
            height: 8px;

            border-radius: 4px;

            /* Inside auto layout */
            flex: none;
            order: 2;
            flex-grow: 0;

            color: var(--text-color-light);
            cursor: pointer;

        }

        &:hover {
            .menu-button {
                background: var(--hover-color);
            }
        }
    }

    .context-balloon {

        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        background-color: var(--neutral-color);

        border-radius: 8px;
        border-bottom: 1px solid var(--elevation-color);

        color: var(--text-color-default);

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;

        &.sent {
            background: var(--theme-color-very-light);
        }

        &.received {
            background: var(--white);
        }

        video, img {
            max-width: 100px;
        }

        &.sticker {
            /* background: none; */
            /* border: none; */
            img {
                max-width: 70px;
            }
        }

    }

    .message-reactions {
        margin-top: -7px;
        z-index: 10;
        background-color: var(--white);
        border-radius: 8px;
        padding: 2px;
    }

    .subject-container {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch; 
    }

    .subject-container {
        
        .subject {
            display: flex;
            height: 32px;
            padding: 0px 12px;
            align-items: center;
            gap: 12px; 

            border-radius: 8px;
            border: 1px solid var(--outline-color);
            background: var(--white); 
            white-space: nowrap;
        }

        .line {
            width: 100%;
            height: 1px; 
            background: var(--outline-color); 
        }
        
    }
}