.page-contacts {

    .item-info-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;

        width: calc(100% - 75px);

        /* Inside auto layout */
        flex: none;
        order: 2;
        flex-grow: 1;
        /* background-color: fuchsia; */
        line-height: 1;
    }

    .item-info-content {
 
        .item-complement {

            div {
                min-width: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .line {
                width: 1px;
                height: 16px; 
                background: var(--text-color-light); 
            }

            span {
                color: var(--text-color-light);

                /* Inside auto layout */
                flex: none;
                order: 0;
                flex-grow: 1;

            }
        }

    }

    .header-info {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        gap: 16px;
        /* position: absolute; */
        order: 2;
        height: 32px;
       /*  right: 16px;
        top: calc(50% - 16px); */
        color: var(--text-color-light);
    }

    .header-channels-icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        gap: 8px;

        height: 32px;

        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;

        /* background-color: yellow; */
    }

    .header-channels-icons {
        div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0;
            gap: 8px;

            width: 32px;
            height: 32px;

            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
        }
    }

    .outline-list {
        border-radius: 4px;
        padding: 0;
        border: var(--borders);
        width: 100%;
        // background-color: gold;
    }

    .outline-list .outline-list-item:first-child {
        border-top: none;
    }

    .outline-list>.outline-list-item {
        border-top: var(--borders);
    }

    .list-item {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;
        height: 72px;
        // background-color: gold;
    }

    .list-item-info-content {
        display: flex;
        align-items: center;
    }

    .list-item-info-content {

        .list-item-name {
            min-width: 200px;
            // background-color: lime;
        }

        .list-item-name {
            span {
                color: var(--text-color-default);
            }
        }

        .list-item-complement {
            span {
                color: var(--text-color-light);
            }
        }

    }

}