.zoom-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.contacts-indicators-zoom {
    -webkit-appearance: none;
    appearance: none;
    height: 8px;
    background: #B279D9;
    border-radius: 5px;
    outline: none;
    transition: background 0.3s ease;
    width: 20%;
}

.contacts-indicators-zoom::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #963CC8;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;
  }