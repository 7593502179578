@font-face {
  font-family: 'Source Sans Pro';
  src: url("../../assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("../../assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("../../assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf");
  font-weight: normal;
}