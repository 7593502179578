.main-menu {
    position: absolute;
    width: var(--main-menu-height);
    left: 0px;
    top: 0px;
    bottom: 0px;
    background: var(--background-color-dark);
}

.main-menu { 

    a, i {
        color: var(--icon-color-light);
    }

    .menu-itens {
        /* Auto layout */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 16px;
        isolation: isolate;

        position: absolute;
        width: 40px;
        left: calc(50% - 40px/2);
        /* top: calc(50% - 320px/2 - 136px); */
        top: var(--header-height);
        /* background-color: red; */
    }

    .menu-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        width: 40px;
        height: 40px;
        border-radius: var(--border-radius);
        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;
        z-index: 1;
        padding: 0;
        font-size: 24px;
    }

    .menu-item.active, .menu-item:hover {
        background-color: var(--theme-color-dark);
    }

    .menu-item.active, .menu-item:hover {
        i::before  {
            color: var(--white);
        }
    }

    .bottom-itens {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 24px;

        position: absolute;
        width: 40px;
        /* height: 148px; */
        left: calc(50% - 40px/2);
        bottom: 16px;
        /* background-color: blueviolet; */
    }

    .bottom-itens {

        .pipeline-step {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            padding: 0px;
            gap: 12px;
            width: 12px;
            height: 84px;
            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
        }

        .pipeline-step-item {
            width: 12px;
            height: 12px;
            opacity: 0.3;
            border-radius: var(--border-radius);
            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
            cursor: pointer;
        }

        .pipeline-step-item.active {
            opacity: 1;
        }

        .pipeline-step-avatar {
            width: 40px;
            height: 40px;
            border-radius: var(--border-radius);
            /* Inside auto layout */
            flex: none;
            order: 1;
            flex-grow: 0;
            position: relative;
        }

        .pipeline-step-avatar {
            .avatar-img {
                position: absolute;
                left: 0px;
                right: 0px;
                top: 0px;
                bottom: 0px;
                background: var(--badge-color);
            }
        }

        .user-avatar {
            width: 40px;
            height: 40px;
            border-radius: var(--border-radius);
            /* Inside auto layout */
            flex: none;
            order: 2;
            flex-grow: 0;
            position: relative;
            cursor: pointer;
        }

    }
    
}