.contact-item-step-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    
    width: 6px;
    height: auto;
    
    border-radius: 2px;
    
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.contact-item-step-content {
    div {
        width: 6px;
        min-width: 6px;
        height: 6px;

        /* Ícones claros */
        background: var(--icon-color-light);
        opacity: 0.3;
        border-radius: 3px;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;

        /* &:nth-child(1) {
            width: 100%;
        }

        &:nth-child(2) {
            width: 75%;
        }
    
        &:nth-child(3) {
            width: 56.25%;
        }
    
        &:nth-child(4) {
            width: 42.18%;
        }
    
        &:nth-child(5) {
            width: 31.63%;
        } */
    }

    .active {
        opacity: 1;
    }
}
