.editor-html {
    .quill {
        width: 100%;
        height: 100%;
    }

    .ql-container {
        width: 100%;
        height: 100%;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        overflow: auto;
    }

    .ql-container.ql-snow {
        border: var(--borders);
        border-radius: 5px 5px 0px 0px;
    }

    .ql-editor {
        width: 100%;
        min-height: 100%;
        font-size: 16px;
        max-height: 35vh;
        padding-bottom: 30px;
        font-family: inherit;
    }

    .ql-editor.ql-blank::before {
        font-style: normal;
        font-size: 14px;
    }

    .ql-snow button {
        text-align: center;
        font-size: 16px;
        width: 28px;
        height: 24px;
    }

    .ql-toolbar button:hover {
        background-color: var(--hover-color);
    }

    .ql-fill,
    .ql-snow.ql-toolbar button:hover,
    .ql-snow .ql-toolbar button:hover,
    .ql-snow.ql-toolbar button:hover .ql-fill,
    .ql-snow .ql-toolbar button:hover .ql-fill,
    .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar button:focus {
        color: var(--text-color-light);
        fill: var(--text-color-light);
    }

    .ql-snow .ql-stroke,
    .ql-snow.ql-toolbar button:hover .ql-stroke,
    .ql-snow .ql-toolbar button:hover .ql-stroke {
        stroke: var(--text-color-light);
    }

    .ql-editor img {
        max-height: 150px;
        max-width: 300px;
    }
}
