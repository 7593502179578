.accordion{
    position: relative;
    box-sizing: border-box;
    display: grid;
    flex-direction: column;
    align-items: flex-start;

    padding: var(--page-box-padding);
    gap: 32px;
    width: 98%;
    background: var(--white);
    padding: 35px 32px 32px 32px;

    border: var(--borders);
    border-radius: var(--border-radius);
}

.accordion {
    .hidden {
        display: none;
    }

    header {
        font-weight: bold;
        cursor: pointer;
    }

    header {
        display: inline-flex;

        .accordion-hidden-content{
            margin: auto;
            margin-left: 2%;
        }

        .icon-container {
            i {
                position: absolute;
                font-size: 19px;
                font-weight: 400;
                color: #707274;
                right: 6%;                
                top: 45px;
            }
        }
    }
}