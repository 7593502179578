.page-csv {
    .disabled-row {
        background-color: var(--hover-color);

        &:hover {
            background-color: var(--hover-color);
        }
    }

    .item-info-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;

        width: calc(100% - 75px);

        flex: none;
        order: 2;
        flex-grow: 1;
        line-height: 1;
    }

    .item-info-content {

        .item-complement {
            div {
                min-width: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .line {
                width: 1px;
                height: 16px; 
                background: var(--text-color-light); 
            }

            span {
                color: var(--text-color-light);

                /* Inside auto layout */
                flex: none;
                order: 0;
                flex-grow: 1;
            }
        }
    }
}