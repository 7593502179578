.page-chat-catacliente {

    .chat-box-catacliente {
        display: flex;
        flex-direction: column;
        gap: 32px;
        flex-shrink: 0; 
        flex-grow: 1; 
        overflow-y: auto;
        margin: 16px;
    }

    .chat-box-catacliente {
        .message {
            display: flex;
            width: 90%;
            align-items: flex-start;
            gap: 16px; 
        }
    
        .information {
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex: 1 0 0; 
        }
    
        .sender {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            gap: 8px;
        }
    
        .right-aligned {
            display: flex;
            align-items: center;
            margin-left: auto; 
        }
    
        .balloons {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2px;
            align-self: stretch; 
        }
    
        .balloons .balloon-container {
            display: flex;
            width: 100%; 
        }
    
        .status-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 0px 3px;
        }
    
        .subject-container {
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: stretch; 
        }
    
        .subject-container .subject {
            display: flex;
            height: 32px;
            padding: 0px 12px;
            align-items: center;
            gap: 12px; 
            border-radius: 8px;
            border: 1px solid var(--outline-color);
            background: var(--white); 
            white-space: nowrap;
        }
    
        .subject-container .line {
            width: 100%;
            height: 1px; 
            background: var(--outline-color); 
        }
    
        .message-balloon {
            position: relative;
            display: flex;
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            border-radius: 0px 8px 8px 8px;
            border-bottom: 1px solid var(--elevation-color);
            color: var(--text-color-default);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            width: 100%;
    
            &.sent {
                background: var(--theme-color-very-light);
            }
    
            &.received {
                background: var(--white);
            }
    
            .message-menu {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    
        .time-status-error {
            color: var(--error-color-default);
        }
    }
      
}
