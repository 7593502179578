.page-container {
    height: 100vh;
}

.page-container {

    .page-side-list {
        background-color: var(--white);
        border-right: var(--borders);
    }

    .menu-item.active {
        background: var(--background-color-light);
    }

    .menu-item:hover {
        background: var(--hover-color);
    }

    .menu-item {
        color: var(--text-color-default);
    }

    .page-content {
        background-color: var(--background-color-light);
    }

    .item-info-content {

        .item-name {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            /* background-color: lime; */
        }

        .item-complement {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            justify-content: flex-start;
            /* background-color: aqua; */
        }

    }

    .page-header {
        
        .item-info-content {
    
            .item-complement {
                justify-content: flex-start;
                max-width: 100%;
            }
    
            .item-complement {

                div {
                    max-width: 50%;
                }
    
                span {
                    color: var(--text-color-light);
    
                    flex: none;
                    order: 0;
                    flex-grow: 1;
    
                }
            }

            /* align-items: stretch; */
            /* width: calc(100% - 260px); */
            width: 0;
            /* background-color: greenyellow; */
    
        }

        .header-info {
            position: initial !important;
            white-space: nowrap;
            /* background-color: fuchsia; */
        }
       
    }

    @media (min-width:0px) and (max-width:599.95px)
    {
        .page-content
        {
            position: absolute;
           /*  width: calc(100% - var(--main-menu-height)); */
            left: var(--main-menu-height);
            flex: auto;
            align-items: stretch;
            height: 100%;
            display: flex;
            z-index: 99999;
        }

    }

    @media (min-width:0px) and (max-width:599.95px)
    {
        .updated_info {
            display: none;
        }

    }

    .page-flex-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: stretch;
    }

    .page-flex-container {

        .page-box-container {
            height: 0;
            flex: auto;
        }

    }

    .page-side-list-header {
        box-sizing: border-box;

        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 24px 16px 24px 24px;
        gap: 16px;
        width: auto;
        height: 72px;
        /* Contornos */
        border-bottom: var(--borders);
        /* Inside auto layout */
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        justify-content: space-between;
        // background-color: #34353642;
    }

    .page-side-list-header {
        div {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            // background-color: gold;
        }

        i {
            font-size: 24px;
        }

        h3 {
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 120%;
            color: #343536;
            font-size: 24px;
        }

        .header-toolbar {
            /* Auto layout */

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            padding: 0px;
            gap: 8px;

            width: 72px;
            height: 32px;


            /* Inside auto layout */

            flex: none;
            order: 2;
            flex-grow: 0;
            // background-color: #343536;
        }

    }

    .page-side-list-search {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 8px;
        width: auto;
        height: 72px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        /* background-color: greenyellow; */
        padding: 0px 16px;
    }

    .page-side-list-options {
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: auto;
        height: 30px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        /* background-color: greenyellow; */
        padding: 0px 16px;
    }

    .page-content-header {
        box-sizing: border-box;

        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 24px 16px 24px 16px;
        gap: 16px;
        width: auto;
        height: 72px;
        background: var(--white);

        /* Contornos */
        border-bottom: var(--borders);

        /* Inside auto layout */
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;

        position: relative;

    }

    .page-box-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
        width: auto;
        /*@todo remover height quando alterar em todas as páginas*/
        height: calc((100vh - var(--header-height)) - (var(--page-box-padding) * 2));
        overflow-y: auto;
        padding: 16px;
        /* background: fuchsia; */
    }

    .page-box {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: var(--page-box-padding);
        gap: 32px;
        width: 100%;
        /* height: 450px; */
        background: var(--white);
        padding: 35px 32px 32px 32px;
      
        border: var(--borders);
        border-radius: var(--border-radius);

        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .page-box {
        header {
            font-weight: 700;
            font-size: 16px;
            color: var(--text-color-dark);
        }
    }

    .drawer {
        overflow-x: hidden;
    }

    .drawer {
        
        & .page-box {
            border: none;
        }

        & .page-box-accordion {
            border: none;
        }
        
    }

    .header-info {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        gap: 16px;

        position: absolute;
        height: 32px;
        right: 16px;
        top: calc(50% - 32px/2);
        color: var(--text-color-light);
        // background-color: blue; 
    }

    .page-side-item-list {
        /* Auto layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 1px;
        width: auto;
        /* Inside auto layout */
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;

        /*auto height*/
        flex: auto;
        height: 0;
        overflow-y: auto;
    }

    @media (min-width:0px) and (max-width:1000px)
    {
        .page-side-item-list
        {
            margin-top: 15px;
            gap: 25px;
        }

    }

    .page-side-item-list {

        .configuration-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 16px;
            gap: 16px;
            width: auto;
            height: 72px;
            /* Inside auto layout */
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;

            .avatar {
                width: 16px;
                height: 16px;
            }
        }

        .configuration-menu-item-accordion {
            width: auto;
            min-height: 72px;
            /* Inside auto layout */
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;

            .avatar {
                width: 16px;
                height: 16px;
            }
        }

        a {
            font-style: normal;
            font-weight: 400;
        }

        .more-items {
            display: flex;
            flex-direction: row;
            height: 20px;
            /* background-color: red; */
            padding: 11px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        .more-items {
           &.disabled{
               cursor: default;
               pointer-events: none;
               color: var(--icon-color-light);
           }
        }

        .alert {
            align-self: stretch;
        }

    }

    .page-side-list-tab {

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px 16px 16px;

        width: auto;
        height: 56px;

        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;

    }

    .page-side-list-tab {

        .tabs {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 4px;
            gap: 8px;

            width: auto;
            height: 40px;


            background: #F6F8FA;
            border-radius: 8px;

            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
        }

        .tab {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 4px;
            gap: 8px;
            isolation: isolate;
            color: var(--background-color-dark);
            border-radius: 6px;

            width: auto;
            height: 32px;

            flex: none;
            order: 0;
            flex-grow: 1;

        }

        .tab.active {
            background: #FFFFFF;
            box-shadow: 0px 1px 2px rgba(32, 37, 41, 0.05), 0px 4px 4px rgba(32, 37, 41, 0.05);
        }

    }

    .more-items {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 11px;
        cursor: pointer;
    }

}