.title-chat-webchat {
   color: var(--title-color);
   font-weight: bold;
   text-align: center;
   font-size: var(--font-size, 14px);
}

.subtitle-chat {
   color: var(--subtitle-color);
   text-align: center;
   font-size: var(--font-size, 14px);
}

.close-chat-button {
   padding: 5px;
   border: none;
   border-radius: 5px;
   background-color: #fff;
   cursor: pointer
}

.fields {
   max-height: 150px;
   overflow-y: auto
}

.button-field {
   font-family: var(--font-family);
   padding: 10px;
   border-radius: var(--border-radius, 20px);
   gap: 10px;
   box-sizing: border-box;
   max-width: 250px;
   flex-wrap: wrap;
   font-size: var(--font-size, 14px);
}

.font-style {
   font-family: var(--font-family);
   font-size: var(--font-size, 14px);
}

.attach-button {
   padding: '5px';
   border-radius: '10px';
   background-color: var(--attatch-button-color)
}

.attached-item {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   flex: 1;
   margin-right: 10px
}

.send-button-attach {
   margin-top: 0;
   margin-right: 10px;
   border: none;
   padding: 10px;
   border-radius: var(--border-radius, 10px); /* Valor padrão: 10px */
   background-color: var(--button-color); /* Cor do botão */
   color: white;
}

.message-input {
   width: 70%;
   margin-right: 10px;
   font-size: var(--font-size, 14px);
}

.send-button-message {
   padding: 10px;
   border-radius: var(--border-radius, 10px);
   color: white;
   background-color: var(--button-color);
}

.floating-icon-chat {
   position: absolute;
   right: 20px;
   background-color: var(--chat-primary-color);
   color: white;
   padding: 10px;
   border-radius: 50%;
   cursor: pointer;
   font-size: 20px;
   text-align: center;
   z-index: 1000;
}