.page-flow {

    .warning-notification {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 95%;
        background-color: var(--warning-color-very-light);
        padding: 8px;
        align-self: center;
    }

    .ri-alert-line {
        color: var(--warning-color-default);
        font-size: 20px;
    }

    .item-info {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .item-info-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: calc(100% - 75px);
        flex: none;
        flex-grow: 0;
        order: 0;
        flex-grow: 1;
        /* background-color: greenyellow; */
        line-height: 1;
    }

    .item-info-content {

        .item-complement {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            justify-content: flex-start;
        }

        .item-complement {

            div {
               
                min-width: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            div.active {
                color: var(--text-color-default);
            }
        }

    }

    .page-box-container {
        background-image: url('../../assets/img/background.png');
    }

    .flow-container {
        display: flex;
        flex-direction: column;
        padding: 24px;
        // background-color: rosybrown;
    }

    .flow-box-row {
        display: flex;
        gap: 40px;
    }

    .flow-box {
        display: flex;
        flex-direction: column;
        // background-color: darkblue;
    }

    .flow-box-property {
        color: var(--text-color-light);
        border-bottom: var(--borders);
    }

    .flow-box {
        .flow-box-container {
            width: 220px;
            border-radius: 12px;
            border: var(--borders);
            box-shadow: 0px 4px 4px 0px rgba(32, 37, 41, 0.05), 0px 1px 2px 0px rgba(32, 37, 41, 0.05);
            margin-bottom: 0px;
        }

        .flow-box-container-not-configured {
            border: 2px solid var(--warning-color-default);
            box-shadow: 0px 4px 7px 0px rgba(214, 50, 50, 0.16);
        }

        .flow-box-container-selected {
            border: 2px solid var(--inst-color-dark);
            box-shadow: 0px 4px 7px 0px rgba(155, 50, 214, 0.16);
        }

        .flow-box-container {

            .flow-box-header {
                display: flex;
                align-items: center;
                gap: 16px;
            }

            .flow-box-body {
                border-top: var(--borders);
                cursor: pointer;
                padding-bottom: 20px;
            }

            .flow-box-body {

                .event-info {
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    justify-content: space-between;
                }

                .event-info {
                    i {
                        color: var(--text-color-light);
                    }

                    .template-name {
                        word-break: break-all;
                    }
                }

            }

        }

        .flow-box-add-menu {
            display: flex;
            justify-content: space-between;
            // background-color: magenta;
            width: 220px;
        }

        .flow-box-count-icon {
            z-index: 1;
            background-color: var(--white);
            border-radius: 6px;
            margin-top: -12px;
            border: var(--borders);
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .flow-box-add-icon {
            background-color: var(--white);
            border-radius: 6px;
            margin-top: -15px;
            border: var(--borders);
        }

        .flow-box-add-icon-disabled {
            background-color: var(--outline-color);
        }

        .flow-box-add-icon-hidden {
            visibility: hidden;
        }
    }


    .connector {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        // background-color: green;
    }

    .connector-tip {
        width: 8px;
        height: 8px;
        border-radius: 2px;
        border: 1px solid var(--block-color-green);
        background-color: var(--white);
    }

    .connector-tip-start {
        margin-top: -15px;
    }

    .connector-line {
        width: 1px;
        height: 100%;
        min-height: 40px;
        background-color: var(--block-color-green);
    }

    .connector-two {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 106px;
        margin-right: 103.5px;
        // background-color: green;
    }

    .connector-two-line-container {
        display: flex;
        padding-left: 4px;
        align-items: flex-end;
        height: 100%;
        min-height: 40px;
        // background-color: rgb(189, 73, 189);
    }

    .connector-two-line {
        height: 50%;
        width: 260px;
        // width: 640px;
        border-top: 1px solid var(--block-color-green);
        border-right: 1px solid var(--block-color-green);
        border-top-right-radius: 5px;
        // background-color: orange;
    }

    .connector-two-tip-end {
        margin-bottom: -9px;
        margin-left: -5.5px;
    }

}