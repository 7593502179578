.page-chat {

    .menu-item, .chat-header {

    .avatar-info {
        position: relative;
    }

        .avatar-info {
            position: relative;
        }

        .avatar-info {
            & > svg, & > .icon-block, & > .icon-contained {
                position: absolute;
                bottom: -4px;
                right: -4px;
            }
        }

        .item-info-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;

            width: calc(100% - 78px);

            /* Inside auto layout */
            flex: none;
            order: 2;
            flex-grow: 1;
            /* background-color: fuchsia; */
            line-height: 1;
        }

        .item-info-content {

            .item-header {
                display: flex;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                justify-content: space-between;
                /* background-color: yellowgreen; */
            }

            .item-header {
                .name {
                    min-width: 0;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    /* background-color: fuchsia; */
                }
                .date {
                    white-space: nowrap;
                }
            }

            .item-complement {
                display: flex;
                align-items: flex-start;
                gap: 8px;
                justify-content: space-between;
                /* background-color: yellowgreen; */
                min-height: 16px;
            }

            .item-complement {

                &.align-self-stretch {
                    align-self: stretch;
                }

                .message {
                    min-width: 0;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 115%;

                    flex-grow: 1;
                }

                .message {
                    color: var(--text-color-light);
                    div {
                        float: left;
                        margin-right: 8px;
                    }
                }

                .line {
                    width: 1px;
                    height: 16px; 
                    background: var(--text-color-light); 
                }

                .counter-badge {
                    order: 2;
                    height: 16px;
                    line-height: 0.7;
                }

                .more-button {

                    /* Auto layout */
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 4px;
                    gap: 8px;

                    width: 0;
                    height: 8px;

                    /* Hover */
                    background: var(--hover-color);
                    border-radius: 4px;

                    /* Inside auto layout */
                    flex: none;
                    order: 2;
                    flex-grow: 0;

                    color: var(--text-color-light);

                    visibility: hidden;

                }

                .more-button {
                    &:focus {
                        visibility: visible;
                        width: 16px;
                    }
                }

                .more-button-visible {
                    visibility: visible;
                    width: 16px;
                }
            }

        }

        &:hover {
            .item-complement {
                .more-button {
                    visibility: visible;
                    width: 16px;
                }
            }
        }

    }

    .chat-header {
        
        .item-info-content {
    
            .item-complement {
                justify-content: flex-start;
                max-width: 100%;
                align-self: flex-start;
            }
    
            .item-complement {

                .message {
                    max-width: 100%;
                }
    
                span {
                    color: var(--text-color-light);
    
                    flex: none;
                    order: 0;
                    flex-grow: 1;
    
                }
            }

            /* align-items: stretch; */
            /* width: calc(100% - 210px); */
            width: 0;
            /* background-color: aquamarine; */
    
        }

        .header-toolbar {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding: 0px;
            gap: 16px;
    
            /* position: absolute; */
            order: 4;
            height: 32px;
            right: 16px;
            top: calc(50% - 32px/2);
            color: var(--text-color-light);
            /* background-color: blue; */
        }
       
    }

    .chat-status-read {
        color: var(--theme-color-dark);
    }

    .chat-status-error {
        color: var(--error-color-default);
    }

    .chat-status-warning {
        color: var(--warning-color-default);
    }

    .text-editor-box {
        display: flex;
        width: auto;
        padding: 8px 16px;
        align-items: flex-end;
        gap: 16px; 
        border-top: 1px solid var(--outline-color);
        background: var(--white);
        position: relative;
        
        .quill {
            width: 100%;
            height: 100%;
        }
    
        .ql-container {
            width: 100%;
            height: 100%;
            padding: 0px;
            display: flex;
            align-items: center;
            border: none;
        }
    
        .ql-editor {
            width: 100%;
            min-height: 100%;
            font-size: 16px;
            max-height: 35vh;
            overflow-y: auto;
        }
    
        .ql-blank::before {
            font-style: normal;
            color: var(--text-color-placeholder);
        }
    
        #toolbar {
            border: none;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 45px;
            gap: 2px;
        }
    
        .ql-snow button {
            text-align: center;
            font-size: 16px;
            width: 28px;
            height: 24px;
        }
    
        .ql-toolbar button:hover {
            background-color: var(--hover-color);
        }
    
        .ql-fill,
        .ql-snow.ql-toolbar button:hover,
        .ql-snow .ql-toolbar button:hover,
        .ql-snow.ql-toolbar button:hover .ql-fill,
        .ql-snow .ql-toolbar button:hover .ql-fill,
        .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
        .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
        .ql-snow.ql-toolbar button:focus
        {
            color: var(--text-color-light);
            fill: var(--text-color-light);
        }
    
        .ql-snow .ql-stroke,
        .ql-snow.ql-toolbar button:hover .ql-stroke,
        .ql-snow .ql-toolbar button:hover .ql-stroke {
            stroke: var(--text-color-light);
        }
    
        .ql-image {
            background-image: none;
        }
        
        .ql-editor img {
            max-height: 150px;
            max-width: 300px;
        }

        .wa-number {
            position: absolute;
            font-size:13px; 
            color: var(--text-color-very-light); 
            margin-left: 12px;
            top: 7px;
        }

        .editor-container {
            height: 50px; 
            display: flex;
            align-items: center;
            padding-left: 15px;
            font-style: normal;
            color: var(--text-color-placeholder);
        }
    }
}