.page-limits-and-utilization {

    .header-info {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        gap: 16px;

        position: absolute;
        height: 32px;
        right: 16px;
        top: calc(50% - 32px/2);
        color: var(--text-color-light);
        /* background-color: blue; */
    }
    
    .page-box-container {
        height: calc(100vh - 75px);
    }

    .page-box {
        padding: 20px;
        
        .box-top {
            width: 100%;
            margin: 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--text-color-light);
        }

        .box-middle {
            width: 100%;
            margin: 0px;
            padding: 0px;

            .chart {
                padding: 0px;
                height: 500px;
            }
        }

        .box-bottom {
            width: 100%;
            margin: 10px 0 0 0;
            color: var(--text-color-light);
        }
    }

    .single-chart {
        cursor: pointer;
    }
}