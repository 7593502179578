.preview-whatsapp {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 10px;
    background-image: url(../../../src/assets/img/whatsapp-background.png);
}

.message-balloon-container {
    padding: 35px 40px 15px 40px;

    .message-balloon {
        width: auto;
        max-width: 430px;
        height: auto;
        display: inline-block;
        background: #f5f5f5;
        border-radius: 10px;
        border-top-left-radius: 0px;
        box-shadow: 0 1px 0.5px #aaa;
        margin: 0 0 25px;
        position: relative;

        .message-content {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 12px 47px 12px 14px;

            p,
            span {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 16px;
                margin: 0;
                white-space: pre-wrap;
                word-wrap: break-word;
            }

            .message-body {
                p {
                    color: #2b2b2b;
                }
            }

            .message-footer {
                p {
                    color: rgba(43, 43, 43, 0.5);
                }
            }

            .timestamp {
                font-size: 12px;
                position: absolute;
                bottom: 3px;
                right: 10px;
                text-transform: uppercase;
                color: #999;
            }
        }

        .message-buttons {
            button {
                font-family: Arial, Helvetica, sans-serif;
                color: #2b96c4;
                font-size: 16px;
                height: 48px;
                background-color: inherit;
                font-weight: 400;
            }
        }

        .message-balloon-arrow:after {
            content: "";
            width: 0px;
            height: 0px;
            position: absolute;
            border-left: 12px solid transparent;
            border-right: 10px solid transparent;
            border-top: 15px solid #f5f5f5;
            border-bottom: 10px solid transparent;
            border-top-left-radius: 3px;
            left: -10px;
            top: 0;
        }
    }
}
