.notistack-SnackbarContainer {
    left: 77px !important;
    bottom: 10px !important;

    min-width: calc(33% - 45px) !important;
    max-width: calc(33% - 45px) !important;
}

.notistack-SnackbarContainer {
    @media (max-width: 599.95px){
      max-width: calc(100% - 92px) !important;
    }  
}

.notistack-SnackbarContainer>div {
    width: 100%;
    min-width: 100%;
}

.notistack-CollapseWrapper {
    padding: 4px 0px !important;
}

.notistack-MuiContent {
    box-shadow: 0px 1px 2px rgba(32, 37, 41, 0.05), 0px 4px 4px rgba(32, 37, 41, 0.05) !important;
    border: 1px solid var(--outline-color) !important;
    border-radius: 8px !important;
    font-size: 16px !important;
    padding: 6px 16px !important;
    min-height: 64px;
    min-width: calc(100% - 37px) !important;
    background-color: var(--white) !important;
    color: var(--text-color-default) !important;
    line-height: 115% !important;
}

.notistack-Snackbar {
    min-width: calc(33% - 45px) !important;
}

#notistack-snackbar {
    max-width: calc(100% - 48px);
}

.notistack-action {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 16px;
    margin-right: -8px;
}

.notistack-MuiContent {
    .icon-block {
        margin-right: 15px;
    }
}

.notistack-message {
    display: flex;
    align-items: center;
    padding: 8px 0;
    max-width: calc(100% - 48px);
}