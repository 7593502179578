@use 'root';
@use 'fonts';

#root {
    height: 100%;
    /* min-height: 100vh; */
    /* background-color: gold; */
}

.logo {
    position: absolute;
    width: 24px;
    height: 24px;
    left: calc(50% - 24px/2);
    top: 24px;
}

.logo {
    img {
        max-width: 24px;
        max-height: 24px;
    }
}

.login-logo {
    max-height: 55px;
    margin-bottom: 30px;
}

main {
    flex: 1;
    padding-left: var(--main-menu-height);
}

a {
    text-decoration: none;
}

.avatar {
    width: 24px;
    height: 24px;
    border-radius: 4px;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.letter {
    p {
        margin: 0;
    }
}


/*classe para o scroll*/
@media only screen and (min-width: 679px) {

    /* firefox */
    * {
        scrollbar-color: var(--theme-color-dark) var(--theme-color-light);
        scrollbar-width: thin;
    }

    /* firefox */

    *::-webkit-scrollbar {
        width: 10px;
    }

    *::-webkit-scrollbar-track {
        background: var(--theme-color-light);
    }

    *::-webkit-scrollbar-thumb {
        background-color: var(--theme-color-dark);
        border-radius: 20px;
        border: 3px solid var(--theme-color-light);
    }

    *::-webkit-scrollbar-thumb:hover {
        border: 1px solid var(--theme-color-light);
    }

    ::-webkit-scrollbar-corner {
        background-color: var(--theme-color-very-light);
        ;
    }
}

.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
}

.stripe-card-element-container {
     background: var(--white);
     border: 1px solid var(--outline-color);
     border-radius: 8px; 
     padding: 0px 10px; 
     max-height: 100px;
}
/*classe para o scroll*/

/*import global fora do .app*/
@import 'app-icons';
@import 'notistack';
@import 'whatsapp';
@import 'editor-html';
@import 'chat-box';
/*import global fora do .app*/

.app {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
}

/*Escopo app*/
.app {
    @import 'main-menu';
    @import 'page';
    @import 'pipeline-step';
    @import 'contacts-indicators';
    @import 'webchat-content-settings';
    @import 'webchats-content';
    @import 'flow-steps';
    @import 'page-contacts';
    @import 'page-account';
    @import 'accordion';
    @import 'page-channel';
    @import 'simple-accordion';
    @import 'page-flow';
    @import 'page-metrics';
    @import 'page-limits-and-utilization';
    @import 'page-chat';
    @import 'page-chat-catacliente';
    @import 'page-csv';

    /*exemplo funil*/
    /* @import 'funnel-graph'; */
    
}